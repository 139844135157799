/* App.css */
body {
  background-color: #000;
  margin: 0;
  padding: 0;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-logo {
  width: 600px; /* Set a fixed width for the logo */
}
  