.App {
    flex-grow: 1; /* Grow the main content to take available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    padding-bottom: 60px;
}