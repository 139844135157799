.footer {
  position:fixed;
  font-size: 40px;
  bottom: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: black; /* Set the line color to white */
}

.footer::before {
  content: "";
  display: block;
  height: 1px; /* Set the height of the white line */
  background-color: white; /* Set the line color to white */
  margin: 0 100px; /* Adjust the margin to control distance from each side */
}

.footer ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.footer ul li {
  display: inline;
  margin: 0 40px;
}

.footer a {
  color: #fff;
  text-decoration: none;
  font-size: 20px; /* Adjust the font size for the links */
  font-weight: bold; /* Set the font weight to normal (not bold) */
}

@media screen and (max-width: 700px) {
  .footer::before {
    margin: 0 20px; /* Adjust the margin to control distance from each side */
  }

  .footer ul li {
    margin: 0 10px;
  }

  .footer a {
    font-size: 16px;
  }
}