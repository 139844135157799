/* PrivacyPolicy.css */
.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: white;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  .logo {
    display: block; /* Make the logo image a block element */
    margin: 0 auto; /* Center the logo horizontally */
    max-width: 200px; /* Adjust the maximum width of the logo as needed */
  }